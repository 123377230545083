<template>
  <DashboardAdminTemplate>
    <div class="pd-1" v-loading="isLoading">
      <h2>แบบประเมินความพึงพอใจในการใช้งานระบบประเมิน TERAK</h2>
      <div class="is-flex">
        <router-link
          :to="{ path: '/user-feedback/person' }"
          class="color-grey-light"
          >สรุปผลประเมินตามรายบุคคล
        </router-link>
        <p class="mg-y-0 color-grey-light mg-x-5 font-20">></p>
        <p class="mg-y-0 color-primary font-20">
          {{ userData.name }} {{ userData.surname }}
        </p>
      </div>

      <div class="bordered pd-1 mg-t-2">
        <h2 class="text-center">ประเมินความพึงพอใจในการใช้งานระบบ TERAK</h2>
        <!-- <h3 class="text-center">{{ userData.name }} {{ userData.surname }}</h3> -->
        <div class="mg-t-1">
          <el-row
            :gutter="20"
            v-for="(item, index) in feedBack"
            :key="index"
            class="rate-item mg-b-5"
          >
            <el-col :lg="16">
              <p class="mg-t-0 mg-b-7">
                {{ index + 1 }}. {{ item.label }}
              </p></el-col
            >
            <el-col :lg="8">
              <el-rate
                disabled
                v-model="item.value"
                class="text-center"
              ></el-rate>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <p class="mg-b-7" v-if="feedData.description">
                ข้อเสนอแนะเพิ่มเติม
              </p>
              <small class="mg-l-3 pd-t-0 font-20">
                {{ feedData.description }}
              </small>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </DashboardAdminTemplate>
</template>
<script>
import DashboardAdminTemplate from "@/template/DashboardAdminTemplate";
import { HTTP } from "@/service/axios";

export default {
  computed: {
    feedBack() {
      return this.$store.state.assessment;
    }
  },
  components: {
    DashboardAdminTemplate
  },
  data() {
    return {
      isLoading: false,
      userData: {
        name: "",
        surname: ""
      },
      feedData: {
        description: "",
        userName: ""
      }
    };
  },
  async mounted() {
    this.feedBack.forEach(element => {
      element.value = 0;
    });
    await this.getRate();
  },
  methods: {
    async getUserById ( id )
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      let res = await HTTP.get(
        `information/${id}/${this.$route.params.companyId}`
      ).catch(e => {
        console.log(e);
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
      });
      // console.log("getUserById", res);
      if (res.data.success) {
        return res.data.obj;
      } else {
        return null;
      }
    },
    async getRate() {
      this.isLoading = true;
      this.feedData = await this.getData(this.$route.params.id).catch(e => {
        console.log(e);
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
      });
      this.userData = await this.getUserById(
        this.feedData.userId,
        this.$route.params.companyId
      ).catch(e => {
        console.log(e);
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
      });
      this.feedBack[0].value = this.feedData.one;
      this.feedBack[1].value = this.feedData.two;
      this.feedBack[2].value = this.feedData.three;
      this.feedBack[3].value = this.feedData.four;
      this.feedBack[4].value = this.feedData.five;
      this.feedBack[5].value = this.feedData.six;
      this.isLoading = false;
    },
    async getData ( id )
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      let res = await HTTP.get(`/complcaency/${id}`).catch(e => {
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
      });
      if (res.data.success) {
        return res.data.obj;
      } else {
        return null;
      }
    }
  }
};
</script>